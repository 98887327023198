body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #FAFAFA;
  font: 12pt "Tahoma";
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.page {
  width: 210mm;
  min-height: 297mm;
  padding: 10mm;
  margin: 10mm auto;
  border: 1px #D3D3D3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.subpage {
  padding: 1cm;
  height: 277mm;
}

.text-header {
  font-size: 1.2rem;
  text-decoration: underline;
}

.grid-partes-1st {
  border: 2px black solid;
}

.grid-partes {
  border: 2px black solid;
  border-top: none;
}

.grid-header{
  font-size: 10pt;
  margin-bottom: 0;
}

.grid-content{
  margin-bottom: 0;
}

@page {
  size: A4;
  margin: 0;
}
@media print {
  html, body {
      width: 210mm;
      height: 297mm;        
  }
  .button{
    visibility: hidden;
  }
  .page {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
  }
}