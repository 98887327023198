* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html, body, #root {
  --white: #FFFFFF;
  --black: #00171F;
  --dark-color: #003459;
  --mid-color: #007EA7;
  --light-color: #00A8E8;
  min-height: 100%;
}

body {
  background: var(--white);
  min-height: 100%;
}

body, input, button {
  font-family: Arial, Helvetica, sans-serif;
  color: var(--black); 
}

.rna-container {
  z-index: 1500;
  position: fixed;
}

.main-container {
  max-width: 90%;
  margin: 0 auto;
  padding: 50px 0;
  text-align: center;
  margin-top: 50px;
}

.main-wide-container {
  max-width: 95%;
  margin: 0 auto;
  padding: 50px 0;
  text-align: center;
  margin-top: 50px;
}

.wide-container {
  text-align: left;
}

.container {
  text-align: left;
}

.main-container .update-icon  {
  color: rgb(196, 196, 196);
  margin-right: 10px;
  float: right;
}

.main-container .del-icon  {
  color: rgb(196, 196, 196);
  margin-right: 10px;
  float: right;
}

.main-container .del-icon:hover  {
  color: #dc3545;
  cursor: pointer;
}

.main-container .update-icon:hover  {
  color: #28a745;
  cursor: pointer;
}

.btn-primary {
  border: 0;
  border-radius: 4px;
  font-size: 20px;
  background: var(--mid-color);
  font-weight: bold;
  color: var(--white);
  cursor: pointer;
}

.btn-primary:hover {
  border: 0;
  border-radius: 4px;
  font-size: 20px;
  background: var(--light-color);
  font-weight: bold;
  color: var(--white);
  cursor: pointer;
}

.btn-primary:disabled {
  border: 0;
  border-radius: 4px;
  font-size: 20px;
  background: var(--mid-color);
  font-weight: bold;
  color: var(--white);
  cursor: not-allowed;
  opacity: 50%;
}

.main-container input:invalid {
  -moz-mox-shadow: none;
}

.icons {
  height: auto;
  width: 100%;
  margin-bottom: 15%;
}

.icons .logo {
  height: auto;
  width: 100%;
  color: var(--dark-color);
}

.main-title {
  color: var(--mid-color); 
  font-weight: bold;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #f5f5f5;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 168, 232, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-toggler {
  color: rgba(0, 168, 232,.8);
  border-color: rgba(0, 168, 232,.5);
}