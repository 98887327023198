/* .navbar {
  margin-left: 10px;
  margin-right: 10px;
  border-left: 1px solid #3A1246;
  border-right: 1px solid #3A1246;
  border-bottom: 3px solid #3A1246; 
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
} */

.navbar {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 1rem;
}

@media (max-width: 980px) {
  .navbar-collapse .navbar-nav .nav-item {
    padding-bottom: 0.5rem;
  }
}

.navbar-light {
  background: var(--dark-color);
} 

.navbar-brand img {
  height: 50px;
  width: auto;
}

.menu-icons .logo {
  height: 50px;
  width: 50px;
  color: var(--light-color);
}

.nav-link {
  cursor: pointer;
}

.menu-icons {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 2px;
  margin-left: 15px;
}

.active button  {
  color: var(--light-color) !important;
  font-weight: bold;
}

.menu-icons .menu-icon {
  height: 25px;
  width: auto;
  align-items: center;
  margin-right: 10px;
}

.menu-icons button {
  background: 0%;
  border: 0;
  color: var(--white);
  display: flex;
  flex-direction: row; 
  align-items: flex-end;
}

.menu-icons button:hover {
  color: var(--light-color);
  cursor:pointer
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}