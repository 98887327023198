.login-container {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container img {
  width: 100%;
  height: auto;
  margin: 0 center;
}

.login-container form {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.login-container form input {
  margin-top: 10px; 
  border: 1px solid var(--dark-color);
  border-radius: 4px;
  height: 48px;
  padding: 0 20px;
  font-size: 16px;
  color: var(--black);
}

.login-container form input::placeholder {
  color: #b2b2b2;
}

.login-container .register-button {
  margin-top: 20px;
  border: 2px solid var(--white);
  border-radius: 4px;
  border-color: var(--dark-color);
  height: 48px;
  font-size: 16px;
  background: var(--white);
  font-weight: bold;
  color: var(--dark-color);
}

.login-container .register-button:hover {
  background: var(--dark-color);
  color: var(--white);
  cursor:pointer
}

.login-container form button {
  margin-top: 20px;
  border: 0;
  border-radius: 4px;
  height: 48px;
  font-size: 16px;
  background: var(--mid-color);
  font-weight: bold;
  color: var(--white);
}

.login-container form button:hover {
  background: var(--light-color);
  cursor:pointer
}